import { useField } from 'vee-validate';
import { formatNumber } from '~/common/functions';

type InputEvent = { target: { value: string }; key: string };

const FIELD_MAX_LENGTH = 9;
const NUMBERS_WITH_SPACES = /^[\d\s]+$/;
/* eslint-disable no-undef */
type Validator = (payload: string) => boolean | string | Promise<boolean | string>;

export default function useFormattingField(
  propsField: ComputedRef<number | null>,
  inputRef: Ref<HTMLInputElement | undefined>,
  emitValue: (payload: number) => void,
  fieldName: string,
  validator: Validator = (payload: string) => true,
) {
  const getInitField = () => unref(propsField)?.toString() ?? '0';

  const {
    value: field,
    errorMessage,
    setState,
  } = useField(fieldName, validator, { initialValue: '' });

  const trimSpaces = (payload: string) => payload.replace(/\s/g, '');
  const getToData = (payload: string | number) => +trimSpaces(payload.toString());
  const getToView = (value: string | number) => formatNumber(getToData(value));

  const checkValid = (event: InputEvent, value: string) => {
    const toDataString = getToData(value).toString();
    const overLimit =
      toDataString.length >= FIELD_MAX_LENGTH && !['Backspace', 'Delete'].includes(event.key);

    const invalidValue = !NUMBERS_WITH_SPACES.test(event.target.value) && event.target.value !== '';

    if (overLimit || invalidValue) {
      const lastValidValue = getToView(getInitField());

      setState({ value: lastValidValue });
      return false;
    }
    return true;
  };

  const setOffset = () => {
    const anchor = inputRef.value as HTMLInputElement;
    const offset = anchor?.selectionEnd as number;
    const incrementOffset = anchor.value.length + 2 === getToView(`${field.value}0`).length;

    setTimeout(() => {
      if (offset !== field.value.length - 1) {
        const newOffset = incrementOffset ? offset + 1 : offset;
        anchor.setSelectionRange(newOffset, newOffset);
      }
    });
  };

  const onInputField = (event: any) => {
    const { value } = event.target as { value: string };
    const valid = checkValid(event, value);
    if (!valid) return;

    const newCost = parseInt(value, 10) ? getToView(value) : value;

    setOffset();

    setState({ value: newCost });

    emitValue(getToData(newCost));
  };

  return {
    field,
    onInputField,
    getToData,
    getToView,
    errorMessage,
  };
}
